/* src/components/Loading.css */
.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1); /* White background with transparency */
    backdrop-filter: blur(8px); /* Blur effect */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; 
  }
  
