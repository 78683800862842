.top-header {
    position: fixed;
    width: 100%;

    top: 0;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.logo-image {
    max-width: 70%;
    max-height: 70%;
    animation: pulse 1.5s infinite;
}




@media (max-width: 600px) {
    .logo-image {
        display: none;
    }
    .welcome-text {
        display: none;
    }
    .user-info {
        display: none;
    }
}
.icon-avatar {
    background-color: rgb(244 244 246);
    color: #000;
    cursor: pointer;
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.icon-avatar:hover {
    background-color: #e0e0e0;
}

.profile-icon {
    background-color: rgb(244, 228, 228);
    color: white;
    cursor: pointer;
    width: 40px;
    height: 40px;
}


.logo-image {
    height: 40px;
}

.log-button{
    background: linear-gradient(135deg, #1675b9 0%, #52c0d9 100%);
}

@media (max-width: 600px) {
    .logo-image {
        display: none;
    }
    .welcome-text {
        display: none;
    }
    .user-info {
        display: none;
    }
}
@media (max-width: 768px) {
    
    .welcome-text{
        margin-left: 1px;
        font-size: 18px;
        color: #003366;
        flex-shrink: 0
    }
    .icon-avatar {
        background-color: rgb(244 244 246);
        color: #000;
        cursor: pointer;
        font-size: 16px;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    
    .logo-image {
        max-width: 30%;
        max-height: 30%;
        animation: pulse 1.5s infinite;
    }
    .profile-icon {
        background-color: rgb(244, 228, 228);
        color: white;
        cursor: pointer;
        width: 28px;
        height: 28px;
    }
   
    .user-info {
        display: none;
    }
    
}
@media (max-width: 1024px) {

    .user-info{
        display:none;
    }
    
   
    .welcome-text{
        margin-left: 10px;
        font-size: 18px;
        color: #003366;
        flex-shrink: 0
    } 
    
}

.notification-drawer {
    max-width: 300px;
    padding: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 60px;
    right: 20px;
    z-index: 1000;
    width: 250px;
  }
  
  .notification-drawer .ant-list-item {
    padding: 10px 0;
  }
  
  .notification-drawer .ant-btn {
    margin-top: 10px;
    padding: 6px 12px;
  }
  

 



