.render-section {
    margin-top: 56px;
    display: flex;
    height: calc(100vh - 56px);
    background-color: #fff;
    overflow: hidden; /* Prevent scrolling on the container */
    
}

.left-section {
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 10px;
}

.right-section {
    padding: 10px;
    margin-top: 10px;
    position: relative;
    width: 100%;
    overflow-y: auto; 
}


.app-container {
    height: inherit;
    overflow: auto;
}




/* For mobile screens hide the sidebar */
@media (max-width: 768px) {
    .left-section {
        display: none;
    }

    /* Show the menu icon for mobile */
    .top-header .anticon-menu {
        display: block;
    }
}

/* For desktop */
@media (min-width: 769px) {
    .left-section {
        display: block;
    }

    .top-header .anticon-menu {
        display: none;
    }
}

/* Style for the drawer's sidebar in mobile */
.mobile-sidebar {
    padding: 0;
}
