* {
  box-sizing: border-box;
  line-height: normal;
}

body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
}

.dhiwise-code,
.dhiwise-navigation {
  min-height: 100%;
}

.common-pointer {
  cursor: pointer;
}

.dhiwise-navigation {
  padding: 20px;
  background-color: #232323;
}

.dhiwise-navigation h1 {
  color: white;
}

.dhiwise-navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}

.dhiwise-navigation ul {
  padding: 0;
}

.dhiwise-navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}

.dhiwise-navigation a {
  color: #132cdc;
}

table {
  border-spacing: 0;
  width: 100%;
}

.common-select {
  z-index: 10000;
}

.react-datepicker-popper {
  z-index: 100000 !important;
}

.drawable-icon {
  position: absolute;
  margin: auto;
  z-index: 1000;
}

.input-wrap {
  position: relative;
}

option {
  color: #000;
}

.table-wrap {
  overflow: auto;
}

input:focus {
  outline: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.slider-indicator:first-child {
  margin-left: 0;
}

.slider-indicator:last-child {
  margin-right: 0;
}

.rc-drawer-content-wrapper {
  width: 100% !important;
}

.rc-drawer-right .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-end;
}

.rc-drawer-left .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-start;
}

.ReactModal__Overlay {
  z-index: 1051;
}

ul li a {
  display: block;
}

.mobile-menu div {
  height: 1.5px;
  width: 20px;
  background-color: #000;
  margin: 4px 0;
}

.mobile-menu {
  display: none;
  cursor: pointer;
}

.header-row {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media only screen and (min-width: 200px) and (max-width: 550px) {
  .mobile-menu {
    display: block;
  }

  .header-row {
    width: 100% !important;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .common-row-list>ul {
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
}

.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}

.menu-overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  /* opacity: 0.7; */
  position: fixed;
  left: 0px;
  top: 0px;
}

.close-line1 {
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.close-line2 {
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.common-row-list>ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list>ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

/*------------------------- header css------------------------------------------- */
.loc {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
} 

.content {
  margin-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
  background: #ffffff;
}

.additional-image {
  width: 100%; 
  height: 300px;
  object-fit: cover; 
  margin-top: 20px;
}

.card-container {
  margin-bottom: 20px; 
}


.card-container .ant-card {
  height: 250px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-container .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; 
  /* background-color: var(--color-bgGreyBlue); */
}

.card-container p {
  margin: 0; 
}

.card-container .ant-card-title {
  font-size: 18px;
  color: #003366;
  
  margin-bottom: 10px;
}



/* Login Page Css ******************************************************************************************************/

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.login-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.logo-container {
  flex: 0 0 45%;
  position: relative;
  background-color: #002d72;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  border-top-right-radius: 50% 1000px;
  border-bottom-right-radius: 50% 1000px;
  overflow: hidden;
  box-shadow: 10px 10px lightblue;
}

.logo-half-circle {
  position: absolute;
  bottom: 0;
  left: -5%;
  width: 50%;
  height: 50%;
  background-color: #002d72;
  border-top-left-radius: 30% 200px;
  border-top-right-radius: 70% 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); 
  }
  100% {
    transform: scale(1); 
  }
}


.logo {
width: 100%;
height: 100vh;
 



}

.text-overlay {
  position: relative;
  color: #4e834b;
  font-weight: 500;
  font-size: 2.25rem;
  width: 100%;
  text-align: center;
  line-height: 1.25;
  transform: translateX(-50px);
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  animation-fill-mode: forwards;
  animation-delay: calc(2* 0.1s);
  padding-top: 60px;
  padding-left: 50px;
 
}

.additional-text {
  margin-top: 10px;
  font-size: 1.25rem;
  color: #4e834b;
  margin-top: 20px;
}

.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Aligns items to the left */
  padding: 20px;
  background-color: #fff;
}

h2 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-weight: bold;
  width: 100%;
  font-size: 24px;
  margin-top: 20px;
}

.input-field {
  /* width: 100%;
  max-width: 500px; 
  padding: 15px; 
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 25px; 
  font-size: 18px;
  margin-top: 20px;  */
  width: 100%;
  padding: 0.5rem 0.75rem;

  border-radius: 8px !important;
  /* Force rounded corners */
  background-color: white;
 
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  margin-top: 20px;
}

.login-button {
  flex: 1;
  padding: 10px;
  /* background-color: #1e668c; */
  background: linear-gradient(135deg, #1675b9 0%, #52c0d9 100%);
  color: #fff;
  border: none;

  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

/* Add button */
.Add-button {
  flex: 1;
  padding: 6px 12px;
  /* Reduce padding for smaller button size */
  background-color: #314ca3;
  color: #fff;
  border: none;
  border-radius: 15px;
  /* Slightly reduce the border-radius for a smaller button */
  font-size: 12px;
  /* Smaller font size */
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 40px;
  /* Reduce height for a smaller button */
  text-align: center;
  /* Center text inside the button */
  white-space: nowrap;
}

.Add-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* Align the button to the left */
}



/* ********************** */

.button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 2px;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.mt-4 {
  margin-top: 1rem;
  /* Adjust as needed */
}

.text-blue-600 {
  color: #2563eb;
  /* Tailwind's blue color */
}

.hover\:underline:hover {
  text-decoration: underline;
}

/* css new changes for side and header */


.page-container {
  display: contents;
  justify-content: center;
  /* Center the card horizontally */
  align-items: center;
  /* Center the card vertically */
  min-height: 10vh;
  /* Full viewport height */
  padding: 10px;
  /* Add padding around the card */
  background-color: hwb(63 51% 15%);
  /* Light background for the whole page */
}

.sidebar {
  height: 100vh;
  overflow-y: auto;
}
.title { 
  font-size: 30px;
  font-weight: bold;
  color:  #1e668c;
  font-family: Muli, Roboto, "Helvetica", Arial, sans-serif;
}

.card {
  background-color: rgb(255 255 255);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 2px rgba(180, 74, 74, 0.1);
  max-width: 100%;
  width: 100%;
  height: fit-content;
  position: fixed;
  top: 0;
  padding: 10px;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  /* Space out the content */
  align-items: flex-end;
  /* Center items vertically */
  width: 100%;
  /* Full width */
  padding: 0px 0;
  /* Vertical padding */
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -0px;

}

.search-container {
  flex: 1;
  /* Take up available space */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
}

/* .buttons-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  padding-left: 1%;
} */

.buttons-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  padding-left: 1%;
  /* width: 80%;  */
  padding: 0%;

}
nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

nav ul li a:hover {
  color: #007bff;
  /* Change color on hover */
}

.sidebarpadding {
  padding-top: 0%;
  padding-bottom: 500vh;
}

/* Sidebar button container */
.sidebar-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;  /* Fixed height */
  padding: 10px 15px; /* Horizontal and vertical padding */
  transition: all 0.3s ease-in-out;
  border-radius: 8px; /* Rounded corners */
}

.sidebar-button:hover {
  background-color: #e0e7ff; /* Light hover background */
}

.sidebar-button span {
  font-size: 20px; /* Icon size */
  color: #6b7280; /* Default text color */
}

.sidebar-button.selected span {
  color: #4A90E2; /* Active text color */
}

.sidebar-button .menu-item-text {
  margin-left: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: #6b7280; /* Default text color */
}

.sidebar-button.selected .menu-item-text {
  color: #4A90E2; /* Active text color */
}

/* **************************************************** */

/* Card Css************** */
.debitcard {
  width: 30%;
  height: auto;
  background-color: #425ebd;
  color: #fff;
  border-radius: 23px;
}

.WeeklyactivityCard {
  width: 60%;
  border-radius: 23px;

}

.ExpenseCard {
  width: 30%;
}

.profile{
  margin-top: -50px;
}
@media only screen and (max-width: 768px) {

  .WeeklyactivityCard,
  .ExpenseCard {
    width: 100%;
  }
}

/* main css */

.main {
  /* background-color: #f4f6f9; */
}


/* Table Css *************************************************** */
.table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}


.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  /* Adjust the vertical spacing between rows here */
}

.table th {
  background-color: #57689f;
  color: #fff;
  padding: 10px;
  text-align: left;
}

.table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.table tr:nth-child(odd) td {
  background-color: #f9f9f9;
}

.table tr:nth-child(even) td {
  background-color: #ffffff;
}

.tableHeader {
  font-weight: bold;
}

.tableCell {
  /* Add any specific styles for table cells if needed */
}

/* *************************** */

/* Form */

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media (max-width: 640px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}

.otp-container {
  display: flex;
  justify-content: space-between;
  gap: 1px;
  /* Space between OTP inputs */
}

.otp-input {
  flex: 1;
  /* Inputs will take equal width */
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
}

@media (max-width: 768px) {
  .otp-container {
    justify-content: space-around;
    gap: 5px;
  }

  .otp-input {
    padding: 8px;
    font-size: 14px;
  }
}


.field-divider {
  border-bottom: 2px solid #333;
  margin: 8px 0;
}

/* src/components/AddBeneficiaryModal.css */

.modal-open .modal-backdrop {
  backdrop-filter: blur(5px);
}

.modal-open .main {
  filter: blur(5px);
  /* pointer-events: none; */
}

.modal-open .main * {
  /* pointer-events: none; */
}

/* add form */
.block {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: gray;
  /* margin-bottom: 15px; */
}

.custom-class {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 1rem;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); */
  padding: 0.5rem;
  /* p-2 */
}


/* Modal.css */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1040;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1050;
  overflow-y: auto;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* text */
.explanation {
  font-size: small;
  font-weight: lighter;
  /* padding: 3px; */
  color: gray;
}

/* Custom styles for Input and Select components */
.form-field {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 8px !important;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.custom-select .ant-select-selector {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 8px !important;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: 2.5rem; /* Adjust the height as needed */
  display: flex;
  align-items: center;
}

.custom-select .ant-select-arrow {
  color: #6b7280;
  /* Adjust the arrow color if needed */
}

.custom-select .ant-select-dropdown {
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}



.form-input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

@media (min-width: 768px) {
  .form-input {
    width: 75%;
  }
}

/* styles.css */
.custom-button {
  cursor: pointer; 
  font-weight: 500;
  line-height: normal;
  min-width: 150px;
  height: 50px;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  /* Text color */
  background-color: #314ca3;
  /* Default background color */
  border-radius: 8px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-button:hover {
  background-color: #243c7a;
  /* Darker shade for hover effect */
}

.dashboard-select {
  color: black;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid lightgray;
  width: 100%;
  padding: 0.5rem;
}



/* Add this to your CSS file or within a <style> tag */
.dropdown-content {
  max-height: 0;
  /* opacity: 0; */
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.dropdown-content.open {
  max-height: 11rem; 
  /* opacity: 1; */
}

/* .custom-border {
  border: 1px solid #d1d5db; 
  border-radius: 0.5rem; 
  padding: 0.75rem; 
} */


/* Modal css */




.form-field-input {
  width: 100%;
  padding: 0.5rem 0.75rem;


  background-color: white;

  outline: none;
  

}
 



/* CSS */
.transaction-item {
  padding: 5px;
  background: #f9f9f9;
  border-radius: 6px;
  margin-bottom: 5px;
}

.txn-date {
  font-size: 1.1rem;
}

.debit-amount {
  color: red;
}

.credit-amount {
  color: green;
}

.transaction-details {
  margin-top: 5px;
}
@media only screen and (max-width: 600px) {
  .transaction-details {
    margin-top: 2px;
  }
  
  
}

@media only screen and (max-width: 600px) {
  .statement-title {
    font-size: 1.4rem;
  }
}




/*Account List*/

.circle-fill{
  width: '8px';
  height: '8px';
  border-radius: '50%';
  background-color: '#003366';
  display: 'inline-block';
  margin-right: '8px';
}



/* Profile image styles */
/* General profile container styles */
.profile-container {
  background-color: #f8f9fa; /* Light background color for the container */
}

/* Profile card styles */
.profile-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff; /* White background for the card */
  padding: 16px;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border: 1px solid #e0e0e0; /* Border color */
  width: 100%; /* Full width */
  max-width: 800px; /* Max width */
  margin: 0 auto; /* Center align */
}

/* Profile header styles */
.profile-header {
  font-size: 24px; /* Larger font size for prominence */
  font-weight: 600; /* Semibold text */
  color: #000; /* Black color */
  margin-bottom: 16px; /* Space between title and card */
}

/* Profile image styles */
.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%; /* Circular image */
  border: 2px solid #007bff; /* Border color */
}

/* Gradient button styles */
.rounded-lg {
  border-radius: 8px; /* Rounded corners */
}



.profile {
  display: block;
  font-size: 0.875rem;
  /* text-sm */
  font-weight: 500;
  /* font-medium */
  color: #314ca3;
  /* text-gray-700 */
  /* margin-bottom: 15px; */
}

.profile-label {
  font-weight: 500;
  color: gray; /* Lighter font color */
  width: 20%; /* Equal width for label */
  text-align: left; /*  aligned to the left */
}
.security-label {
  font-weight: 500;
  color: gray; /* Lighter font color */
  width: 100%; /* Equal width for label */
  text-align: left; /*  aligned to the left */

}
.security-value {
  width: 100%; /* Equal width for value */
  text-align: left; /* Align value to the left to match label alignment */
  color: #000; /* Darker font color */
  font-size: 15px;
}
.profile-value {
  width: 100%; /* Equal width for value */
  text-align: left; /* Align value to the left to match label alignment */
  color: #000; /* Darker font color */
  font-size: 15px;
}
.profile-details {
  width: 100%;
  padding: 20px;
  text-align: left;
}
.profile-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.profile-font{
font-size: 14px;
/* color: rgb(55, 52, 52); */
/* color:'text-gray-600' */
}

.cheque{
 height: '3.5rem';
  width: '18rem';
  /* border: '1px solid #6b7280'; */
  border-radius: '6px'; 
}







.custom-divider {
  border-color: #003366;
  border-width: 1px;
}



.fd-rate-table .ant-table-thead > tr > th {
  background-color: #d0e7ff;
  padding: 8px; 
  font-size: 14px; 
}

.fd-rate-table .ant-table-tbody > tr > td {
  padding: 8px; 
  font-size: 14px;
}

.fd-rate-table .ant-table-tbody > tr:nth-child(even) {
  background-color: #f5f5f5; 
}

.fd-rate-table .ant-table-tbody > tr:nth-child(odd) {
  background-color: #ffffff;
}

.fd-rate-table {
  width: 100%; 
  overflow-x: auto; 
}

.fd-rate-table .ant-table-tbody > tr > td {
  text-align: right; 
}



.verify-card {
  background-color: #f9f9f9;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



.otp-input-container {
  display: flex;
  justify-content: center;
  gap: 15px; 
  margin: 20px 0;
}

.otp-input-field {
  width: 50px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-right: -2px;
}


.resend-otp-button {
  background: none;
  border: none;
  color: #1890ff;
  cursor: pointer;
  font-size: 14px;
}


@media only screen and (max-width: 600px) {
  
  .otp-input-container {
    display: flex;
    justify-content: space-around;
    gap: 15px; 
    margin: 0px 0;
  }
}








.gradient-button {
  /* background: rgb(2, 0, 36); */
  background: linear-gradient(135deg, #1675b9 0%, #52c0d9 100%);
  /* background: linear-gradient(90deg, rgb(59, 84, 170) 0%, rgb(59, 84, 170) 0%, rgb(122, 143, 208) 71%); */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.gradient-button:hover {
  background: linear-gradient(135deg, #1675b9 0%, #52c0d9 100%);
}
